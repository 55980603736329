import { Guid } from "guid-typescript";
export type coefficientOptionOperationFamilies = {
    key:string,
    value:string,
    id:string
}
export type operationFamilyCoefficientOption = {
    operationFamilyId:string,
    operationFamilyKey:string,
    secondValue:string
    value:string
}
export default class CoefficientConfig {
    public id: string;
    public value: number;
    public secondValue: number|undefined;
    public name: string;
    public coefficientId: string;
    public bopModelId: string;
    public unitId: any;
    public unitSymbol: any;
    public flagAction: boolean;
    public isMontageVisible: boolean;

    //only for nature des taches
    public operationFamilies:Array<coefficientOptionOperationFamilies>
    public operationFamiliesToDelete:Array<string>
    public operationFamilyCoefficientOptionList:Array<operationFamilyCoefficientOption>
    constructor(bopModelId:string,coefficientId:string) {
        this.id = Guid.create().toString();
        this.value = 0;
        this.name = '';
        this.bopModelId = bopModelId;
        this.coefficientId = coefficientId;
        this.unitId = null;
        this.unitSymbol = "";
        this.flagAction = false;
        this.isMontageVisible = false;
        this.operationFamilies = []
        this.operationFamiliesToDelete = []
        this.operationFamilyCoefficientOptionList = []

    }
  }
  