


































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import CoefficientOption, {coefficientOptionOperationFamilies} from "@/models/CoefficientConfig";
import CoefficientService from "@/services/CoefficientService";
import UnitService from "@/services/UnitService";
import { Guid } from "guid-typescript";
import CoefficientSelectedValueService from "@/services/CoefficientSelectedValueService";
import CoefficientOptionService from "@/services/CoefficientOptionService";
import {
  RubriqueEnum,
  CoefficientTypeEnum,
  UserRoleEnum,
  BopModelEnum,
} from "@/utils/Enums";
import Constants from "@/utils/Constants";
import ExportService from "@/services/ExportService";
import OperationTypeService from "@/services/OperationTypeService";
import table from "@/utils/table";
type Data = {
  columns: Array<Object>;
  rows: Array<Object>;
};

const MONTAGE_DEMONTAGE_OPERATION_FAMILY_KEY = "montageDemontage"
@Component({
  components: {
    Table: () => import("@/components/Shared/Table/Table.vue"),
    OperationFamiliesModal: () =>
        import("@/components/Shared/Table/OperationFamiliesModal.vue")
  },
})
export default class CoefficientDimensionsSetup extends Vue {
  @Prop({ required: true }) public bopModelId!: string;
  @Prop({ required: true }) public bopModelKey!: string;
  @Prop({ required: true }) public coefficientId!: string;
  @Prop({ required: true }) public coefficientMetadata!: any;

  public data: Data = {
    columns: [],
    rows: [],
  };
  private get exportBopModelUrl():string {
    return ExportService.exportBopModelUrl(this.bopModelKey,this.$i18n.locale);
  }
  public showModal: boolean = false;
  private url: string = "";
  private modifiedCoefficientConfigs: Array<CoefficientOption> = [];
  private  get language():string{
    return this.$i18n.locale;
  }
  private get isNatureTacheCaloApp():boolean{
    return this.bopModelKey === BopModelEnum.CalorifugageAppareil &&
        this.coefficientMetadata.key === "K2";
  }
  public isModalVisible: boolean = false;
  private modalProps: any = null;

  /**
   * Methods
   */
  public onDuplicateData(data){
    if(!this.isNatureTacheCaloApp)return;
    data.isNew = true;
    data.operationFamilies.forEach(el => {
      el.id = Guid.createEmpty().toString();
    });
  }
  public onDiscardedOperationFamilies(obj: {discardedOperationFamilies : coefficientOptionOperationFamilies[],rowData:CoefficientOption}){

    const table = (<any>this.$refs.table);
    if(!table.isNewRow(obj.rowData)){
      this.operationFamiliesToDelete = obj.discardedOperationFamilies.map(el => el.id);
      obj.rowData.operationFamiliesToDelete = this.operationFamiliesToDelete;
    }

  }

  public validate(obj: any) {
    //
    const table = (<any>this.$refs.table);
    table.modify(obj);
    this.isModalVisible = false;
  }
  public openModal(props: any) {
    // console.log("Im called man");
    this.isModalVisible = true;
    this.modalProps = props;
  }
  private async remove(data: any) {
    const modalResponse = await this.$bvModal.msgBoxConfirm(
      this.$t("bopModelSetup.coefficientSetup.confirmDelteMessage").toString(),
      {
        title: this.$t(
          "bopModelSetup.coefficientSetup.confirmDelteTitle"
        ).toString(),
        size: "lg",
        buttonSize: "lg",
        okVariant: "primary",
        cancelVariant: "secondary",
        okTitle: this.$t(
          "bopModelSetup.coefficientSetup.confirmDelteYes"
        ).toString(),
        cancelTitle: this.$t(
          "bopModelSetup.coefficientSetup.confirmDelteNo"
        ).toString(),
        footerClass: "p-2",
        hideHeaderClose: false,
        centered: true,
        dialogClass: "modal-app",
      }
    );

    if (modalResponse) {
      // console.log(data);
      const rowData = data.rowData;
      try {
        const response = this.isNatureTacheCaloApp ? await this.deleteNatureDesTaches(rowData)
            : await CoefficientOptionService.deleteCoefficientOption(
                rowData.id
            );
        if (response.status === 200) {
          this.handleSuccess(data);
        }
      } catch (err) {
        this.handleError(err);
      }
    }
  }
  private async deleteNatureDesTaches(rowData):Promise<any>{
    if(rowData.isNew){
      return {
        status: 200
      };
    }
    return CoefficientOptionService.deleteNatureDesTachesCoefficient(
        rowData.natureTachesCoefficientOptionId,
        rowData.operationFamilies.map(el => el.key)
    );
  }
  private handleSuccess(data) {
    this.$notify({
      group: "global",
      type: "success",
      title: this.$t(
          "bopModelSetup.coefficientSetup.deleteSuccessTitle"
      ).toString(),
      text: this.$t(
          "bopModelSetup.coefficientSetup.deleteSuccess"
      ).toString(),
    });
    (<any>this.$refs.table).deleteRow(data.rowIndex);
    const index = this.modifiedCoefficientConfigs.findIndex(el => el.id === data.rowData.id);
    this.modifiedCoefficientConfigs.splice(index,1)

  }
  private handleError(err) {
    const errResponse = err.response;
    let text = this.$t(
        "bopModelSetup.coefficientSetup.deleteError"
    ).toString();
    if (errResponse.status === 500) {
      if (errResponse.data.detail === "instanciatedInBop") {
        text = this.$t(
            "bopModelSetup.coefficientSetup.deleteErrorUsedInBop"
        ).toString();
      } else if (errResponse.data.detail === "definingAnOperation") {
        text = this.$t(
            "bopModelSetup.coefficientSetup.definingAnOperation"
        ).toString();
      }
    }
    this.$notify({
      group: "globalError",
      type: "error",
      title: this.$t(
          "bopModelSetup.coefficientSetup.deleteErrorTitle"
      ).toString(),
      text,
      duration: Constants.DEFAULT_WAIT_ERROR_NOTIFICATIONS,
    });
  }
  public async save() {
    if(this.isNotAllowedToEdit)return;
    this.isSaveDisabled = true;
    if (this.modifiedCoefficientConfigs.length) {
      try {
        const response = await CoefficientOptionService.SaveCoefficientOptions(
          this.modifiedCoefficientConfigs,
          this.language
        );
        if (response.status === 200) {
          this.$notify({
            group: "global",
            type: "success",
            title: this.$t(
              "bopModelSetup.coefficientConfig.saveSuccess.title"
            ).toString(),
            text: this.$t(
              "bopModelSetup.coefficientConfig.saveSuccess.text"
            ).toString(),
          });
          this.refresh();
        }
      } catch (err) {
        if (err.response.status === 400) {
          const text = this.getBadRequestTextError(err);

          this.$notify({
            group: "globalError",
            type: "error",
            title: this.$t(
              "bopModelSetup.coefficientConfig.saveError.title"
            ).toString(),
            text,
            duration: Constants.DEFAULT_WAIT_ERROR_NOTIFICATIONS,
          });
        }
      }finally {
        this.isSaveDisabled = false;
      }
    }
  }
  private getBadRequestTextError(err):string {

    switch (err.response.data) {
      case "duplicateCoeffOption":
        return this.$t("bopModelSetup.coefficientConfig.duplicateCoefficientOption").toString();
      case "noOperationFamilySelected":
          return this.$t("bopModelSetup.coefficientConfig.noOperationFamilySelected").toString();
      case "potentielDuplicateSecondValue":
        return this.$t("bopModelSetup.coefficientConfig.potentielDuplicateMontage").toString();
      case "potentielDuplicateValue":
        return this.$t("bopModelSetup.coefficientConfig.potentielDuplicateDemontage").toString();
      case "duplicateCoefficientOptionValues":
        return this.$t("bopModelSetup.coefficientConfig.duplicateCoefficientOptionValues").toString();
    }
    return this.$t(
        "bopModelSetup.coefficientConfig.saveError.text"
    ).toString();
  }
  public async addData() {
    const newCoefficientConfig = new CoefficientOption(
      this.bopModelId,
      this.coefficientId
    );

    (<any>this.$refs.table).updatedRows.unshift(newCoefficientConfig);
    (<any>this.$refs.table).modify(newCoefficientConfig);
  }
  private isSaveDisabled:boolean = false;
  private operationFamiliesToDelete: string[] = [];
  get isNotAllowedToEdit(): Boolean {
    return !this.$store.getters.isAllowed(UserRoleEnum.centralAdministrator) || this.isSaveDisabled;
  }

  public refresh(): void {
    this.modifiedCoefficientConfigs = [];
    (<any>this.$refs.table).refreshAPI();
  }
  private  async loadData(){
    const units = (await UnitService.getUnits()).data;
    // gets the coefficient metadata to construct the table

    let dataColumns: Array<any> = [];
    this.url = CoefficientOptionService.GetCoefficientOptionsTableUrl(
        this.coefficientId,
        this.language
    );

    switch (this.coefficientMetadata.coefficientType) {
      case CoefficientTypeEnum.T2:
        dataColumns.push(
            ...[
              {
                name: "editable",
                title: this.$t("bopModelSetup.coefficientValue"),
                property: "value",
                sortField: "value",
              },
              {
                name: "dropdown",
                title: this.$t("bopModelSetup.coefficientUnit"),
                property: "unitId",
                display: "unitSymbol",
                options: units,
                sortField: "unitSymbol",
                valueField: "key",
                textField: "value",
              },
              {
                name: "actions",
                title: "",
                removeButton: true,
                editButton: true,
                duplicateButton: true,
                disableRemoveButton: this.isNotAllowedToEdit,
                disableEditButton: this.isNotAllowedToEdit,
                disableDuplicateButton: this.isNotAllowedToEdit,
              },
            ]
        );
        break;
      case CoefficientTypeEnum.T3:
      case CoefficientTypeEnum.T4:
      case CoefficientTypeEnum.CoefficienSupp:
        const canHaveFlagAction =
            this.bopModelKey === BopModelEnum.CalorifugageAppareil &&
            this.coefficientMetadata.canHaveFlagAction;
            ;
        dataColumns = [
          {
            name: "editable",
            title: this.$t("bopModelSetup.coefficientName"),
            sortField: "name",
            property: "name",
          },
          {
            name: "editable",
            title: this.isNatureTacheCaloApp ? this.$t("bopModelSetup.caloAppCoefficientValueDemontage") : this.$t("bopModelSetup.coefficientValue"),
            property: "value",
            sortField: "value",
            isNatureTacheField: this.isNatureTacheCaloApp ,
            isMontage : false
          },
        ];
        if (this.isNatureTacheCaloApp) {
          const operationFamilies = (
              await OperationTypeService.listOperationFamiliesByParentAndBopModel(
                  this.bopModelId,
                  MONTAGE_DEMONTAGE_OPERATION_FAMILY_KEY,
                  this.language
              )
          ).data;
          dataColumns.splice(1,0,{
            name: "operationFamilies",
            title: this.$t("bopModelSetup.coefficientOptionOperationFamily"),
            property: "operationFamilies",
            sortField: "operationFamilies",
            operationFamilies
          });
          dataColumns.push({
            name: "editable",
            title: this.$t("bopModelSetup.caloAppCoefficientValueMontage"),
            property: "secondValue",
            sortField: "secondValue",
            isNatureTacheField: true,
            isMontage : true
          });
        }
        if (canHaveFlagAction) {
          dataColumns.push({
            name: "switch",
            sortField: "flagAction",
            title: this.isNatureTacheCaloApp  ? this.$t(
                `coefficientOptions.demontageVisibility`
            ).toString() : this.$t(
                `coefficientOptions.${this.coefficientMetadata.key}FlagAction`
            ).toString(),
            property: "flagAction",
            isMontage: false,
            isNatureTacheField: this.isNatureTacheCaloApp,
          });
        }
        if(this.isNatureTacheCaloApp){
          dataColumns.push({
            name: "switch",
            sortField: "isMontageVisible",
            title: this.$t(
                `coefficientOptions.montageVisibility`
            ).toString(),
            property: "isMontageVisible",
            isMontage: true,
            isNatureTacheField: this.isNatureTacheCaloApp,
          });
        }



        dataColumns.push({
          name: "actions",
          title: "",
          removeButton: true,
          editButton: true,
          duplicateButton: true,
          disableRemoveButton: this.isNotAllowedToEdit,
          disableEditButton: this.isNotAllowedToEdit,
          disableDuplicateButton: this.isNotAllowedToEdit,
        });
        break;
    }

    this.data.columns = dataColumns;
  }
  /**
   * Hooks
   */
  public created() {
    this.loadData();
  }
}
